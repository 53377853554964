import "../../../typings/common";
import "../../../typings/client";
import $ from "jquery";
import "external-svg-loader";

import version from "../../../common/version";

$("[data-version").text(version);

import "./bg_image_crop"

export * as Notifications from "./notifications";