import $ from "jquery";

$("[bg-img-crop-container]").each((_i, element) => {
    const elt = $(element);
    const image = elt.find("[bg-img-crop-img]");
    image.width("100%")
    image.height(elt.height() || 0)
    window.addEventListener("resize", () => {
        image.width("100%")
        image.height(elt.height() || 0)
    })
})