export type IUser = {
	id: string;
};

export type IUserSafe = Omit<IUser, "password">;
export type IUserPublic = Pick<IUser, "id">;
export type IUserSkeleton = Omit<IUser, "password" | "datetimeCreated" | "id">;

export interface ErrorConstructor {
	captureStackTrace(thisArg: any, func: any): void;
}
